<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="6"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              Register Payment
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="register__payment-card"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="6"
        >
          <b-media
            no-body
            class="register__payment-left-media"
          >
            <b-media-aside class="register__payment-left-media-icon">
              <b-avatar
                size="48"
                variant="light-primary"
                rounded
              >
                <img :src="dollarPlusIcon">
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto register__payment-left-media-text">
              <b-card-text class="font-small-3 mb-0 text-primary text-uppercase">
                Making Payment For
              </b-card-text>
              <h4 class=" mb-0">
                {{ fileDetail.nvsFileType || '-' }} {{ fileDetail.lotLocation ? `(${fileDetail.lotLocation})` : '' }}
              </h4>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          v-if="fileDetail && fileDetail.ledgerSummary"
          class="content-header-right register__payment-right-media-wrapper"
          md="6"
          cols="12"
        >
          <b-media
            no-body
            class="register__payment-right-media"
          >
            <b-media-aside class="register__payment-right-media-icon">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <img :src="handIcon">
              </b-avatar>
            </b-media-aside>
            <b-media-body class="register__payment-right-media-text">
              <h4 class="font-weight-bolder mb-0">
                {{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.totalPayment) ? `$${numberFormat(fileDetail.ledgerSummary.totalPayment)}` : '-' }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Previously Paid
              </b-card-text>
            </b-media-body>
          </b-media>
          <b-media
            no-body
            class="register__payment-right-media"
          >
            <b-media-aside class="register__payment-right-media-icon">
              <b-avatar
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="register__payment-right-media-text">
              <h4 class="font-weight-bolder mb-0">
                {{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.outstanding) ? `$${numberFormat(fileDetail.ledgerSummary.outstanding)}` : '-' }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Outstanding
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>

      <hr class="dividerHR">
      <validation-observer
        ref="paymentCreateForm"
        #default="{invalid}"
      >
        <b-form @submit.prevent="previewForm">
          <b-row>
            <b-col
              md="6"
              cols="12"
            >
              <b-form-group
                label="Customer"
                label-for="h-booking-customerName"
                label-class="register__payment-label"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer"
                  vid="customerName"
                  rules="required"
                >
                  <!-- <vue-autosuggest
                    ref="autocomplete"
                    v-model="query"
                    class="customer-autosuggest"
                    :suggestions="suggestions"
                    :input-props="inputProps"
                    :get-suggestion-value="getSuggestionValue"
                    @selected="setCustomer"
                    @input="fetchResults"
                  >
                    <template slot="before-suggestions">
                      <span
                        class="weight-600 suggestion-header"
                      >
                        Customers found
                      </span>
                    </template>
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item text-primary">{{ suggestion.item.Name }}</span>
                      <br>
                      <span>{{ suggestion.item.Per_Tel_No_Hp }} / {{ suggestion.item.NRIC }} {{ suggestion.item.Per_Email ? `/ ${suggestion.item.Per_Email}` : '' }}</span>
                    </template>
                    <template slot="after-input">
                      <div
                        v-if="query && suggestions.length === 0"
                        class="search-results suggestion-header"
                      >
                        <span class="weight-600">No result found.</span>
                      </div>
                    </template>
                  </vue-autosuggest> -->
                  <b-input-group>
                    <b-form-input
                      id="search_name"
                      v-model="query"
                      label="Customer"
                      name="name"
                      placeholder="Search Customer Name, NRIC, Mobile No. or Email"
                      autocomplete="off"
                      tabindex="0"
                      :class="!showCustomerCrossIcon ? 'input__append-border-right' : ''"
                      @keyup="fetchResults()"
                      @focusout="resetCustomerSearchResult"
                    />
                    <b-input-group-append
                      v-if="showCustomerCrossIcon"
                      is-text
                    >
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer text-primary"
                        size="18"
                        data-toggle
                        @click="clearCustomerSearchInput"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <div
                    v-show="showCustomerSearchResult"
                    class="search-results mt-50 z-index-99"
                  >
                    <div v-if="foundCustomers.length">
                      <b-list-group flush>
                        <b-list-group-item>
                          <h3 class="mb-0 result-search-header">
                            Customers found
                          </h3>
                        </b-list-group-item>
                        <b-list-group-item
                          v-for="(customer, cIndex) in foundCustomers"
                          :key="cIndex"
                          class="cursor-pointer"
                          @click="setCustomer(customer)"
                        >
                          <h6 class="mb-0 fs-14 text-primary">
                            {{ customer.name }}
                          </h6>
                          <p class="mb-0 fs-12">
                            {{ customer.perContact }} / {{ customer.nric }} {{ customer.perEmail ? `/ ${customer.perEmail}` : '' }}
                          </p>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </div>
                  <div
                    v-show="searchCustomerResultNotFound"
                    class="search-results mt-50 z-index-99"
                  >
                    <b-list-group>
                      <b-list-group-item>
                        <h3 class="mb-0 result-search-header z-index-99">
                          No result found.
                        </h3>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider
                v-if="searchType === 'customer'"
                #default="{ errors }"
                name="File No."
                vid="fileID"
                rules="required"
              >
                <b-form-group
                  label="File No."
                  label-for="h-bookings-fileID"
                  label-class="register__payment-label"
                  label-cols-md="2"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    id="h-bookings-fileID"
                    v-model="fileID"
                    label="fileNo"
                    placeholder="Search File No. or Lot No."
                    :options="fileOptions"
                    :reduce="f => f.fileID"
                    :clearable="false"
                    @input="setPaymentInformation"
                  />
                </b-form-group>
              </validation-provider>

              <b-form-group
                v-else
                label="File No."
                label-for="h-booking-fileNo"
                label-class="register__payment-label"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="File No"
                  vid="fileNo"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="h-booking-fileNo"
                      v-model="fileQuery"
                      label="File No."
                      name="name"
                      placeholder="Search File No. or Lot No."
                      autocomplete="off"
                      tabindex="0"
                      :class="!showFileCrossIcon ? 'input__append-border-right' : ''"
                      @keyup="fetchFileResults()"
                      @focusout="resetFileSearchResult"
                    />
                    <b-input-group-append
                      v-if="showFileCrossIcon"
                      is-text
                    >
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer text-primary"
                        size="18"
                        data-toggle
                        @click="clearFileSearchInput"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <div
                    v-show="showFileSearchResult"
                    class="search-results mt-50 z-index-99"
                  >
                    <div v-if="foundFiles.length">
                      <b-list-group flush>
                        <b-list-group-item>
                          <h3 class="mb-0 result-search-header">
                            Files found
                          </h3>
                        </b-list-group-item>
                        <b-list-group-item
                          v-for="(file, cIndex) in foundFiles"
                          :key="cIndex"
                          class="cursor-pointer"
                          @click="setFile(file)"
                        >
                          <h6 class="mb-0 fs-14 text-primary">
                            {{ file.fileNo }}
                          </h6>
                          <p class="mb-0 fs-12">
                            {{ file.nvsFileType }} / {{ file.lotLocation }}
                          </p>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </div>
                  <div
                    v-show="searchFileResultNotFound"
                    class="search-results mt-50 z-index-99"
                  >
                    <b-list-group>
                      <b-list-group-item>
                        <h3 class="mb-0 result-search-header z-index-99">
                          No result found.
                        </h3>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-table-simple
                responsive
                caption-top
                class="installment__table"
              >
                <caption class="text-bold-black">
                  Upcoming/Overdue Installments
                </caption>
                <b-tbody v-if="installmentList.length">
                  <b-tr
                    v-for="(installment, index) in installmentList"
                    :key="index"
                  >
                    <b-td width="75%">
                      <div class="d-flex">
                        <div class="d-flex align-items-center">
                          <b-form-checkbox
                            v-model="selectedInstallments"
                            class="installment__checkbox"
                            :value="installment.sequence"
                            :disabled="installment.paymentStatus === 'Paid'"
                            @click.native.stop
                            @change="selectInstallment(installment)"
                          />
                          <!-- <b-form-checkbox
                            v-model="selectedInstallments"
                            class="installment__checkbox"
                            :value="installment.sequence"
                            disabled
                            @click.native.stop
                            @change="selectInstallment(installment)"
                          /> -->
                        </div>
                        <div class="installment__description">
                          Installment #{{ installment.sequence }} <br>
                          <small class="text-muted">Due {{ dateFormat(installment.dueDate) }}</small>
                        </div>
                      </div>
                    </b-td>
                    <b-td>
                      <b-badge :variant="`light-${resolveStatusVariant(installment.paymentStatus)}`">
                        {{ installment.paymentStatus }}
                      </b-badge>
                    </b-td>
                    <b-td class="text-right">
                      ${{ numberFormat(installment.principleAmount) }}
                      <br>
                      <span
                        v-if="installment.principleAmount > installment.remaining"
                        class="text-muted-o"
                      >
                        Paid ${{ installment.principleAmount - installment.remaining }}
                      </span>
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tbody v-else>
                  <b-tr>
                    <b-td>No data yet.</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot v-if="installmentList.length">
                  <b-td
                    colspan="3"
                    class="register__payment-button-td"
                  >
                    <div class="d-flex justify-content-between">
                      <b-button
                        :class="installmentList.length > selectedInstallments.length ? '' : 'disabled'"
                        class="pl-0"
                        variant="link"
                        @click="selectAllInstallments"
                      >
                        Select All
                      </b-button>
                      <b-button
                        v-if="viewAll"
                        class="pr-0"
                        variant="link"
                        @click="showFullSchedule"
                      >
                        View All
                        <feather-icon
                          icon="ChevronDownIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="viewLess"
                        class="pr-0"
                        variant="link"
                        @click="hideFullSchedule"
                      >
                        View Less
                        <feather-icon
                          icon="ChevronUpIcon"
                        />
                      </b-button>
                    </div>
                  </b-td>
                </b-tfoot>
              </b-table-simple>

              <b-table-simple
                v-if="otherFees && otherFees.length"
                responsive
                caption-top
                class="installment__table"
              >
                <caption class="text-bold-black">
                  Other Fees
                </caption>
                <b-tbody>
                  <b-tr
                    v-for="(invoice, index) in otherFees"
                    :key="index"
                  >
                    <b-td width="87.5%">
                      <div class="d-flex">
                        <div class="d-flex align-items-center">
                          <b-form-checkbox
                            v-model="selectedOtherFees"
                            class="installment__checkbox"
                            :value="invoice.trxID"
                            @click.native.stop
                            @change="selectOtherFee(invoice)"
                          />
                        </div>
                        <div class="installment__description">
                          Reactivate_Fee (Reactivation Fees)
                        </div>
                      </div>
                    </b-td>
                    <b-td class="text-right">
                      ${{ invoice.balance }}
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-td
                    colspan="3"
                    class="register__payment-button-td"
                  >
                    <b-button
                      v-if="otherFees.length > selectedOtherFees.length"
                      class="pl-0"
                      variant="link"
                      @click="selectAllOtherFees"
                    >
                      Select All
                    </b-button>
                  </b-td>
                </b-tfoot>
              </b-table-simple>

              <b-table-simple
                v-if="otherInvoices && otherInvoices.length"
                responsive
                caption-top
                class="installment__table"
              >
                <caption class="text-bold-black">
                  Other Invoices
                </caption>
                <b-tbody>
                  <b-tr
                    v-for="(invoice, index) in otherInvoices"
                    :key="index"
                  >
                    <b-td width="87.5%">
                      <div class="d-flex">
                        <div class="d-flex align-items-center">
                          <b-form-checkbox
                            v-model="selectedInvoices"
                            class="installment__checkbox"
                            :value="invoice.trxID"
                            @click.native.stop
                            @change="selectInvoice(invoice)"
                          />
                        </div>
                        <div class="installment__description">
                          <strong class="text-primary">{{ invoice.trxNo }}</strong>
                        </div>
                      </div>
                    </b-td>
                    <b-td class="text-right">
                      ${{ invoice.balance }}
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-td
                    colspan="3"
                    class="register__payment-button-td"
                  >
                    <b-button
                      v-if="otherInvoices.length > selectedInvoices.length"
                      class="pl-0"
                      variant="link"
                      @click="selectAllInvoices"
                    >
                      Select All
                    </b-button>
                  </b-td>
                </b-tfoot>
              </b-table-simple>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <b-card class="payment__card">
                <b-form-group
                  label="Amount to be Paid*"
                  label-cols-md="5"
                  label-class="d-flex align-items-center register__payment-label"
                  label-for="totalPayable"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Amount to be Paid"
                    vid="totalPayable"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                      class="input-group-merge payment__card-large-input-prepend"
                    >
                      <b-form-input
                        id="totalPayable"
                        v-model="totalPayable"
                        class="payment__card-large-input"
                        :state="(errors.length > 0) ? false : null"
                        name="totalPayable"
                        placeholder="0.00"
                        @input="selectPaymentObjects()"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <div
                  v-if="selectedInstallmentObjects.length || selectedInvoiceObjects.length || selectedOtherFees.length"
                  :class="{ 'payment__for-card-primary': !showMoreAmountError && !upcomingItemsError, 'payment__for-card-danger': showMoreAmountError || upcomingItemsError }"
                >
                  <b-table-simple
                    responsive
                    caption-top
                    borderless
                    class="payment__for-table"
                  >
                    <caption class="text-bold-black">
                      Payment For
                    </caption>
                    <b-tbody>
                      <b-tr
                        v-for="(installment, index) in selectedInstallmentObjects"
                        :key="'installment' + index"
                      >
                        <b-td width="87.5%">
                          <div class="d-flex">
                            <div class="register__payment-round-block">
                              <span class="register__payment-round-number">{{ index + 1 }}</span>
                            </div>
                            <!-- <div
                              v-if="installment.paymentStatus == 'Upcoming'"
                              class="installment__description"
                              style="text-decoration:line-through"
                            >
                              Installment #{{ installment.sequence }}
                            </div> -->
                            <div
                              class="installment__description"
                            >
                              Installment #{{ installment.sequence }}
                            </div>
                          </div>
                        </b-td>
                        <b-td class="text-right">
                          <div>
                            <strong v-if="installment.principleAmount == installment.allocated">${{ numberFormat(installment.allocated) }}</strong>
                            <span v-else-if="installment.principleAmount > installment.remaining"><strong>${{ numberFormat(installment.allocated) }}</strong> of ${{ numberFormat(installment.remaining) }}</span>
                            <span v-else><strong>${{ numberFormat(installment.allocated) }}</strong> of ${{ numberFormat(installment.principleAmount) }}</span>
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr
                        v-for="(invoice, index) in selectAllOtherFeesObjects"
                        :key="'other-fee' + index"
                      >
                        <b-td width="87.5%">
                          <div class="d-flex">
                            <div class="register__payment-round-block">
                              <span class="register__payment-round-number">{{ selectedInstallmentObjects.length + index + 1 }}</span>
                            </div>
                            <div class="installment__description">
                              Reactivate_Fee (Reactivation Fees)
                            </div>
                          </div>
                        </b-td>
                        <b-td class="text-right">
                          <strong v-if="invoice.balance == invoice.allocated">${{ invoice.allocated }}</strong>
                          <span v-else><strong>${{ invoice.allocated }}</strong> of ${{ invoice.balance }}</span>
                        </b-td>
                      </b-tr>
                      <b-tr
                        v-for="(invoice, index) in selectedInvoiceObjects"
                        :key="'invoice' + index"
                      >
                        <b-td width="87.5%">
                          <div class="d-flex">
                            <div class="register__payment-round-block">
                              <span class="register__payment-round-number">{{ selectedInstallmentObjects.length + selectAllOtherFeesObjects.length + index + 1 }}</span>
                            </div>
                            <div class="installment__description">
                              {{ invoice.trxNo }}
                            </div>
                          </div>
                        </b-td>
                        <b-td class="text-right">
                          <strong v-if="invoice.balance == invoice.allocated">${{ invoice.allocated }}</strong>
                          <span v-else><strong>${{ invoice.allocated }}</strong> of ${{ invoice.balance }}</span>
                        </b-td>
                      </b-tr>
                      <!-- <b-tr>
                        <b-td width="87.5%">
                          <div class="d-flex">
                            <div class="register__payment-round-block">
                              <span class="register__payment-round-number">3</span>
                            </div>
                            <div class="installment__description">
                              Reactivate_Fee (Reactivation Fees)
                            </div>
                          </div>
                        </b-td>
                        <b-td>
                          <strong>$25.80</strong>
                        </b-td>
                      </b-tr> -->
                    </b-tbody>
                  </b-table-simple>

                  <div
                    v-if="showMoreAmountError"
                    class="payment__for-alert"
                  >
                    <div class="d-flex">
                      <div class="payment__for-alert-icon">
                        <feather-icon
                          icon="AlertTriangleIcon"
                          size="18"
                          class="mr-50 text-danger"
                        />
                      </div>
                      <div class="installment__description">
                        Current selection is insufficient (requires ${{ numberFormat(moreAmount) }} more).
                        <br>
                        <strong
                          class="text-danger cursor_pointer"
                          @click="resetAmount"
                        >Reset Selection</strong>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="upcomingItemsError"
                    class="payment__for-alert"
                  >
                    <div class="d-flex">
                      <div class="payment__for-alert-icon">
                        <feather-icon
                          icon="AlertTriangleIcon"
                          size="18"
                          class="mr-50 text-danger"
                        />
                      </div>
                      <div class="installment__description">
                        Some of the items you have selected won't be paid.
                        <br>
                        <strong
                          class="text-danger cursor_pointer"
                          @click="resetUpcomingInstallment"
                        >Reset Selection</strong>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="payment__for-card-danger">
                  <b-table-simple
                    responsive
                    caption-top
                    borderless
                    class="payment__for-table"
                  >
                    <caption class="text-bold-black">
                      Payment For
                    </caption>
                    <b-tbody>
                      <b-tr>
                        <b-td width="87.5%">
                          <div class="d-flex">
                            <div class="register__payment-round-block">
                              <span class="register__payment-round-number">1</span>
                            </div>
                            <div class="installment__description">
                              Installment #14
                            </div>
                          </div>
                        </b-td>
                        <b-td>
                          <strong>$445.80</strong>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="87.5%">
                          <div class="d-flex">
                            <div class="register__payment-round-block">
                              <span class="register__payment-round-number">2</span>
                            </div>
                            <div class="installment__description">
                              Installment #15
                            </div>
                          </div>
                        </b-td>
                        <b-td>
                          <strong>$445.80</strong>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="87.5%">
                          <div class="d-flex">
                            <div class="register__payment-round-block">
                              <span class="register__payment-round-number">3</span>
                            </div>
                            <div class="installment__description">
                              Reactivate_Fee (Reactivation Fees)
                            </div>
                          </div>
                        </b-td>
                        <b-td>
                          <strong>$25.80</strong>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div class="payment__for-alert">
                    <div class="d-flex">
                      <div class="payment__for-alert-icon">
                        <feather-icon
                          icon="AlertTriangleIcon"
                          size="18"
                          class="mr-50 text-danger"
                        />
                      </div>
                      <div class="installment__description">
                        Current selection is insufficient (requires $54.20 more).
                        <br>
                        <strong class="text-danger">Reset Selection</strong>
                      </div>
                    </div>
                  </div>
                </div> -->

                <b-form-group
                  label-cols-md="12"
                  label="Payment Mode*"
                  label-for="payment-mode"
                  label-class="register__payment-label"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Payment Mode"
                    rules="required"
                  >

                    <b-form-radio-group
                      id="event-custom-field-"
                      v-model="paymentMode"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio equal_widthRadio"
                      @change="changeCardOption"
                    >
                      <b-form-radio
                        value="Card"
                        class="register__payment-icon-checkbox"
                      >
                        <img
                          :src="cardIcon"
                          class="register__payment-icon"
                        >
                        <br>
                        <span class="register__payment-text">Card</span>
                      </b-form-radio>
                      <b-form-radio
                        value="Cash"
                        class="register__payment-icon-checkbox"
                      >
                        <img
                          :src="dollarIcon"
                          class="register__payment-icon"
                        >
                        <br>
                        <span class="register__payment-text">Cash</span>
                      </b-form-radio>
                      <b-form-radio
                        value="GIRO"
                        class="register__payment-icon-checkbox"
                      >
                        <img
                          :src="giroIcon"
                          class="register__payment-icon"
                        >
                        <br>
                        <span class="register__payment-text">GIRO</span>
                      </b-form-radio>
                      <b-form-radio
                        value="NETS"
                        class="register__payment-icon-checkbox"
                      >
                        <img
                          :src="netsIcon"
                          class="register__payment-icon"
                        >
                        <br>
                        <span class="register__payment-text">NETS</span>
                      </b-form-radio>
                      <b-form-radio
                        value="Cheque"
                        class="register__payment-icon-checkbox"
                      >
                        <img
                          :src="chequeIcon"
                          class="register__payment-icon"
                        >
                        <br>
                        <span class="register__payment-text">Cheque</span>
                      </b-form-radio>
                    </b-form-radio-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group v-if="paymentMode === 'Card'">
                  <validation-provider
                    #default="validationContext"
                    name="Payment Option"
                    rules="required"
                  >

                    <b-form-radio-group
                      id="event-custom-field-"
                      v-model="cardOption"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio radiogGap10"
                      @change="changeCardType"
                    >
                      <b-form-radio
                        value="IPP"
                        class="register__payment-icon-checkbox"
                      >
                        <span class="register__payment-text">IPP</span>
                      </b-form-radio>
                      <b-form-radio
                        value="Direct Debit"
                        class="register__payment-icon-checkbox"
                      >
                        <span class="register__payment-text">Direct Debit</span>
                      </b-form-radio>
                      <b-form-radio
                        value="One Time"
                        class="register__payment-icon-checkbox"
                      >
                        <span class="register__payment-text">One Time</span>
                      </b-form-radio>
                    </b-form-radio-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group v-if="paymentMode === 'GIRO'">
                  <validation-provider
                    #default="validationContext"
                    name="Payment Option"
                    rules="required"
                  >

                    <b-form-radio-group
                      id="event-custom-field-"
                      v-model="cardOption"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio radiogGap10"
                      @change="changeCardType"
                    >
                      <b-form-radio
                        value="Bank Transfer"
                        class="register__payment-icon-checkbox"
                      >
                        <span class="register__payment-text">Bank Transfer</span>
                      </b-form-radio>
                      <b-form-radio
                        value="PayNow"
                        class="register__payment-icon-checkbox"
                      >
                        <span class="register__payment-text">PayNow</span>
                      </b-form-radio>
                      <b-form-radio
                        value="Direct Debit"
                        class="register__payment-icon-checkbox"
                      >
                        <span class="register__payment-text">Direct Debit</span>
                      </b-form-radio>
                    </b-form-radio-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group v-if="(paymentMode === 'Card' && ['IPP', 'Direct Debit', 'One Time'].includes(cardOption)) || (paymentMode === 'GIRO' && ['Direct Debit'].includes(cardOption))">
                  <validation-provider
                    #default="validationContext"
                    name="Card"
                    rules="required"
                  >

                    <b-form-radio-group
                      id="event-custom-field-"
                      v-model="cardType"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio radiogGap5"
                      @change="changeCardItems"
                    >
                      <b-form-radio
                        value="Visa Card"
                        class="register__payment-logo-checkbox"
                      >
                        <img
                          :src="visaIcon"
                          class="register__payment-logo-white"
                        >
                      </b-form-radio>
                      <b-form-radio
                        value="Master Card"
                        class="register__payment-logo-checkbox"
                      >
                        <img
                          :src="masterCardIcon"
                          class="register__payment-logo"
                        >
                      </b-form-radio>
                      <b-form-radio
                        value="Amex Card"
                        class="register__payment-logo-checkbox"
                      >
                        <img
                          :src="amexIcon"
                          class="register__payment-logo"
                        >
                      </b-form-radio>
                      <b-form-radio
                        value="China Union Pay"
                        class="register__payment-logo-checkbox"
                      >
                        <img
                          :src="unionPayIcon"
                          class="register__payment-logo"
                        >
                      </b-form-radio>
                      <b-form-radio
                        value="JCB"
                        class="register__payment-logo-checkbox"
                      >
                        <img
                          :src="jcbIcon"
                          class="register__payment-logo"
                        >
                      </b-form-radio>
                    </b-form-radio-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Transaction Date*"
                  label-for="h-event-transactionDate"
                  label-cols="12"
                  label-class="register__payment-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Transaction Date"
                    vid="transactionDate"
                    rules="required"
                  >
                    <b-input-group>
                      <flat-pickr
                        id="h-event-transactionDate"
                        v-model="transactionDate"
                        class="form-control flat-pickr-group"
                        :config="{ wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true, maxDate:'today' }"
                        @input="transactionDateValidation == true ? transactionDateValidation = false : null"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="CalendarIcon"
                          class="cursor-pointer"
                          data-toggle
                          size="18"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="transactionDateValidation"
                      class="text-danger"
                    >
                      {{ transactionDateError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Payment Reference*"
                  label-for="h-event-paymentRef"
                  label-cols-md="12"
                  label-class="register__payment-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Payment Reference"
                    vid="paymentRef"
                    rules="required"
                  >
                    <b-form-input
                      id="h-event-paymentRef"
                      v-model="paymentRef"
                      placeholder=""
                      :state="(errors.length > 0 || paymentRefValidation) ? false : null"
                      name="name"
                      @input="paymentRefValidation == true ? paymentRefValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="paymentRefValidation"
                      class="text-danger"
                    >
                      {{ paymentRefError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Remarks"
                  label-for="h-event-description"
                  label-cols-md="12"
                  label-class="register__payment-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Remarks"
                    vid="remarks"
                    rules=""
                  >
                    <div
                      style="position: relative;"
                      class="mb-50"
                    >
                      <b-form-textarea
                        id="h-event-remarks"
                        v-model="remarks"
                        placeholder="Remarks"
                        :state="(errors.length > 0 || remarksValidation) ? false : null"
                        name="remarks"
                        maxlength="100"
                        @input="remarksValidation == true ? remarksValidation = false : null"
                      />
                      <div style="position: absolute; bottom: 7px; color: #999999; right: 20px;">
                        {{ remarks.length }}/100
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <strong
                      v-if="remarks.length > 100"
                      class="text-danger"
                    >
                      You have exceeded the maximum character limit
                    </strong>
                    <small
                      v-if="remarksValidation"
                      class="text-danger"
                    >
                      {{ remarksError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                @click="cancelPayment"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                :disabled="invalid || showMoreAmountError || upcomingItemsError || !(totalPayable > 0) || remarks.length > 100"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  class="mr-50"
                />
                <span class="align-middle">Register Payment</span>
              </b-button>
            </li>
          </b-nav>
        </b-form>
      </validation-observer>
    </b-card>

    <!-- register payment item modal -->
    <b-modal
      id="modal-payment-items"
      ref="payment-items-modal"
      modal-class="status-update-modal"
      size="lg"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitForm"
      @cancel="closePaymentPreviewModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header">
          <img
            :src="plusIcon"
            style="width:80px; height: auto; margin-bottom: 17px"
          >
          <div class="stock-in-item-modal-heading-block">
            <h4 style="font-weight: 500; font-size: 21px; color: #000000;">
              Proceed with Payment Registration?
            </h4>
            <p style="color: #333333;">
              You are going to register the following payment of <strong>{{ fileDetail.fileNo }} - {{ fileDetail.nvsFileType || '-' }} {{ fileDetail.lotLocation ? `(${fileDetail.lotLocation})` : '-' }}</strong> under <strong>{{ customerDetail.name }} ({{ customerDetail.nric }})</strong>
            </p>
          </div>
        </div>
      </template>
      <b-table-simple
        responsive
        sticky-header
        class="shadow-table"
      >
        <b-thead>
          <b-tr>
            <b-th style="width:60%; height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important;">
              Status
            </b-th>
            <b-th
              style="height: 0px !important;"
            >
              Amount
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(installment, index) in selectedInstallmentObjects"
            :key="'installment' + index"
          >
            <b-td>
              <span class="text-bold-black">Installment #{{ installment.sequence }}</span>
              <br>
              <span class="text-muted">Due {{ dateFormat(installment.dueDate) }}</span>
            </b-td>
            <b-td>
              <b-badge :variant="`light-${resolveStatusVariant(installment.paymentStatus)}`">
                {{ installment.paymentStatus }}
              </b-badge>
            </b-td>
            <b-td>
              ${{ numberFormat(installment.allocated) }}
              <!-- <span
                v-if="installment.principleAmount > installment.remaining"
                class="text-muted-o"
              >
                Paid ${{ installment.principleAmount - installment.remaining }}
              </span> -->
            </b-td>
          </b-tr>
          <b-tr
            v-for="(invoice, index) in selectAllOtherFeesObjects"
            :key="'other-fees' + index"
          >
            <b-td>
              <span class="text-bold-black">Reactivate_Fee (Reactivation Fees)</span>
            </b-td>
            <b-td />
            <b-td>
              ${{ numberFormat(invoice.allocated) }}
            </b-td>
          </b-tr>
          <b-tr
            v-for="(invoice, index) in selectedInvoiceObjects"
            :key="'invoice' + index"
          >
            <b-td>
              <span class="text-bold-black">{{ invoice.trxNo }}</span>
            </b-td>
            <b-td />
            <b-td>
              ${{ numberFormat(invoice.allocated) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="3">
              <p class="mb-0">
                <span class="text-bold-black">Bank In Code</span> {{ bankInCode }}
              </p>
              <p class="mb-0">
                <span class="text-bold-black">Payment Reference</span> {{ paymentRef }}
              </p>
              <p class="mb-0">
                <span class="text-bold-black">Remarks</span> {{ remarks }}
              </p>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-between">
        <p><span class="text-bold-black mr-75">{{ transactionDate }}</span></p>
        <p><span class="text-bold-black">Total Amount Payable</span> <span class="ml-75"> ${{ numberFormat(totalPayable) }} by {{ paymentMode }}{{ cardOption ? ` - ${cardOption}` : '' }}{{ cardType ? ` - ${cardType}` : '' }}</span></p>
      </div>
      <div
        v-if="consequent_error"
        class="register__payment-modal-alert"
      >
        <div class="d-flex">
          <div class="register__payment-modal-icon">
            <feather-icon
              icon="AlertTriangleIcon"
              size="18"
              class="mr-50 text-danger"
            />
          </div>
          <div class="installment__description text-danger">
            <strong>Error:</strong>
            The earliest installment must be included, and selected installments must be consecutive.
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          type="button"
          :disabled="consequent_error"
          @click="ok()"
        >
          <span class="align-middle">Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ register payment item modal -->
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BTableSimple, BThead, BTbody, BTfoot, BTr, BTh, BTd, BBadge, BMedia, BMediaAside, BAvatar, BMediaBody,
  BButton, BFormGroup, BInputGroup, BFormInput, BFormTextarea, BFormCheckbox, BInputGroupAppend, BFormInvalidFeedback,
  BFormRadioGroup, BFormRadio, BForm, BNav, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
// import { VueAutosuggest } from 'vue-autosuggest'
// import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationContentCustom from '../../operation/ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BForm,
    BNav,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BListGroup,
    BListGroupItem,

    ValidationObserver,
    ValidationProvider,
    // VueAutosuggest,
    vSelect,
    flatPickr,
  },
  directives: { Ripple },
  data() {
    return {
      /* eslint-disable global-require */
      plusIcon: require('@/assets/images/icons/payments/plus_icon.png'),
      handIcon: require('@/assets/images/icons/payments/hand.png'),
      dollarPlusIcon: require('@/assets/images/icons/payments/dollarPlus.png'),
      cardIcon: require('@/assets/images/icons/payments/credit-card.svg'),
      dollarIcon: require('@/assets/images/icons/payments/dollar.svg'),
      giroIcon: require('@/assets/images/icons/payments/giro.svg'),
      netsIcon: require('@/assets/images/icons/payments/nets.svg'),
      chequeIcon: require('@/assets/images/icons/payments/cheque.svg'),
      visaIcon: require('@/assets/images/icons/payments/visa.svg'),
      masterCardIcon: require('@/assets/images/icons/payments/mastercard.svg'),
      amexIcon: require('@/assets/images/icons/payments/amex.svg'),
      unionPayIcon: require('@/assets/images/icons/payments/unionPay.svg'),
      jcbIcon: require('@/assets/images/icons/payments/jcb.svg'),

      booking: {},
      bookingID: '',
      stringID: '',
      status: 'confirmed',
      statusValidation: false,
      statusError: 'Valid status is required',
      dutyOfficer: '',
      dutyOfficerValidation: false,
      showCustomFormSection: false,
      dutyOfficerError: 'Valid duty officer is required',
      dutyOfficerOptions: [],
      bookingActivityLogs: [],
      statusDataOptions: [
        { title: 'Confirmed', code: 'confirmed' },
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Payment', code: 'pending-payment' },
        { title: 'Pending Approval', code: 'pending-approval' },
        { title: 'Waiting List', code: 'waiting-list' },
        { title: 'Cancelled', code: 'cancelled' },
      ],
      transactionDate: moment().format('DD/MM/YYYY'),
      transactionDateValidation: false,
      transactionDateError: 'Valid transaction date is required',
      paymentRef: '',
      bankInCode: '',
      bankInId: '',
      paymentRefValidation: false,
      paymentRefError: 'Valid payment reference is required',
      name: '',
      nameValidation: false,
      customField: [],
      remarks: '',
      remarksValidation: false,
      remarksError: 'Valid description is required',
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 1000,
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Search Customer Name, NRIC, Mobile No. or Email',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {},
      customerID: '',
      fileID: '',
      fileOptions: [],
      fileDetail: {},
      customerDetail: {},
      installmentList: [],
      selectedInstallments: [],
      selectedInstallmentObjects: [],
      selectedInvoiceObjects: [],
      selectAllOtherFeesObjects: [],
      selectedOtherFees: [],
      selectedInvoices: [],
      remarksArray: [],
      remarksOtherFeesArray: [],
      remarksInvoiceArray: [],
      remarksFinalArray: [],
      viewAll: false,
      viewLess: false,
      totalPayable: 0,
      paymentMode: '',
      cardOption: '',
      cardType: '',
      showMoreAmountError: false,
      upcomingItemsError: false,
      moreAmount: 0,
      showCustomerCrossIcon: false,
      showCustomerSearchResult: false,
      searchCustomerResultNotFound: false,
      foundCustomers: [],
      fileQuery: '',
      showFileCrossIcon: false,
      showFileSearchResult: false,
      searchFileResultNotFound: false,
      foundFiles: [],
      searchType: 'file',
      consequent_error: false,
      required,
      otherFees: [],
      otherInvoices: [],
      installmentInvoices: [],
    }
  },
  beforeMount() {
    if (this.$route.query.fileID) {
      this.fileID = this.$route.query.fileID
      this.setPaymentInformation()
    }
  },
  methods: {
    // fetchResults() {
    //   const { query } = this

    //   clearTimeout(this.timeout)
    //   this.timeout = setTimeout(() => {
    //     this.$http.get('appointment/bookings/respond-with/customers', { params: { search: query } })
    //       .then(response => {
    //         if (response.data.customers.length === 0) {
    //           this.suggestions = []
    //         } else {
    //           this.suggestions = [{
    //             data: response.data.customers,
    //           }]
    //         }
    //         this.fileOptions = []
    //         this.customerID = ''
    //         this.customerDetail = {}
    //         this.fileID = ''
    //         this.fileDetail = {}
    //         this.installmentList = []
    //         this.selectedInstallments = []
    //         this.selectedInstallmentObjects = []
    //         this.selectedOtherFees = []
    //         this.selectedInvoices = []
    //         this.totalPayable = 0
    //       })
    //       .catch(error => {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //             icon: 'AlertTriangleIcon',
    //             variant: 'danger',
    //           },
    //         })
    //       })
    //   }, this.debounceMilliseconds)
    // },
    cancelPayment() {
      this.$swal({
        title: 'Cancel Payment?',
        html: 'Are you sure you want to cancel this payment registration? This action cannot be undone, and you will need to start a new payment registration from the beginning.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'customers-files-show', params: { id: this.fileDetail.fileID }, query: { tab: 'payment' } })
          }
        })
    },
    resetAmount() {
      this.totalPayable = this.actualPayable
      this.showMoreAmountError = false
      this.moreAmount = 0
    },
    resetUpcomingInstallment() {
      const upcomingInstallments = this.selectedInstallmentObjects.filter(installment => installment.paymentStatus === 'Upcoming')
      // this.upcomingItemsError = false
      let reducedAmount = 0
      upcomingInstallments.forEach(installment => {
        this.selectedInstallments.splice(this.selectedInstallments.indexOf(installment.sequence), 1)
        this.selectedInstallmentObjects.splice(this.selectedInstallmentObjects.indexOf(installment), 1)
        // this.remarksArray.splice(this.remarksArray.indexOf(`Instmt#${installment.sequence}_${moment(installment.dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')}`), 1)
        reducedAmount += Number(installment.remaining)
      })
      this.totalPayable -= reducedAmount
      this.upcomingItemsError = false
      if (this.selectedInstallmentObjects.length === 0) {
        this.remarksArray = []
      } else if (this.selectedInstallmentObjects.length === 1) {
        const text = `INSTALLMENT #${this.selectedInstallmentObjects[0].sequence}, ${moment(this.selectedInstallmentObjects[0].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')}`
        this.remarksArray = [text]
      } else {
        // eslint-disable-next-line prefer-destructuring
        const length = this.selectedInstallmentObjects.length
        const text = `INSTALLMENT #${this.selectedInstallmentObjects[0].sequence}, ${moment(this.selectedInstallmentObjects[0].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')} - INSTALLMENT #${this.selectedInstallmentObjects[length - 1].sequence}, ${moment(this.selectedInstallmentObjects[length - 1].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')} (${length} MONTHS)`
        this.remarksArray = [text]
      }
      this.remarksFinalArray = this.remarksArray.concat(this.remarksOtherFeesArray, this.remarksInvoiceArray)
      this.remarks = this.remarksFinalArray.join(', ')
    },
    fetchResults() {
      const { query } = this

      if (query && query.length > 2) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$http.get('appointment/bookings/respond-with/customers', { params: { search: query } })
            .then(response => {
              if (response.data.customers.length === 0) {
                this.foundCustomers = []
                this.searchCustomerResultNotFound = true
                this.showCustomerSearchResult = false
              } else {
                this.foundCustomers = response.data.customers
                this.showCustomerSearchResult = true
                this.searchCustomerResultNotFound = false
              }
              this.fileOptions = []
              this.customerID = ''
              this.customerDetail = {}
              this.fileID = ''
              this.fileDetail = {}
              this.installmentList = []
              this.selectedInstallments = []
              this.selectedInstallmentObjects = []
              this.selectedOtherFees = []
              this.selectedInvoices = []
              this.totalPayable = 0
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }, this.debounceMilliseconds)
      }
    },
    // setCustomer(data) {
    //   const { item } = data
    //   this.getFiles(item.Entity_Id)
    //   this.customerDetail = item
    //   this.customerID = item._id
    // },
    setCustomer(customer) {
      this.customerDetail = customer
      this.customerID = customer._id
      this.showCustomerCrossIcon = true
      this.query = customer.name
      this.searchType = 'customer'
      this.getFiles(customer.entityID)
    },
    resetCustomerSearchResult() {
      setTimeout(() => {
        this.searchCustomerResultNotFound = false
        this.showCustomerSearchResult = false
      }, 500)
    },
    clearCustomerSearchInput() {
      this.fileQuery = ''
      this.query = ''
      this.customerID = ''
      this.showCustomerCrossIcon = false
      this.searchCustomerResultNotFound = false
      this.showCustomerSearchResult = false
      this.fileOptions = []
      this.customerDetail = {}
      this.fileID = ''
      this.fileDetail = {}
      this.installmentList = []
      this.selectedInstallments = []
      this.selectedInstallmentObjects = []
      this.selectedOtherFees = []
      this.selectedInvoices = []
      this.totalPayable = 0
      this.actualPayable = 0
      this.searchType = 'file'
      this.foundFiles = []
    },
    fetchFileResults() {
      const { fileQuery } = this
      if (fileQuery && fileQuery.length > 2) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$http.get('customer/files/respond-with/files', { params: { search: fileQuery } })
            .then(response => {
              if (response.data.files.length === 0) {
                this.foundFiles = []
                this.searchFileResultNotFound = true
                this.showFileSearchResult = false
              } else {
                this.foundFiles = response.data.files
                this.showFileSearchResult = true
                this.searchFileResultNotFound = false
              }
              this.fileOptions = []
              this.customerID = ''
              this.customerDetail = {}
              this.fileID = ''
              this.fileDetail = {}
              this.installmentList = []
              this.selectedInstallments = []
              this.selectedInstallmentObjects = []
              this.selectedOtherFees = []
              this.selectedInvoices = []
              this.totalPayable = 0
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }, this.debounceMilliseconds)
      }
    },
    setFile(file) {
      // eslint-disable-next-line prefer-destructuring
      this.customerDetail = file.purchasers[0]
      this.query = this.customerDetail.name
      this.fileID = file.fileID
      this.showFileCrossIcon = true
      this.fileQuery = file.fileNo
      this.setPaymentInformation()
    },
    resetFileSearchResult() {
      setTimeout(() => {
        this.searchFileResultNotFound = false
        this.showFileSearchResult = false
      }, 500)
    },
    clearFileSearchInput() {
      this.query = ''
      this.fileQuery = ''
      this.customerID = ''
      this.showCustomerCrossIcon = false
      this.searchCustomerResultNotFound = false
      this.showCustomerSearchResult = false
      this.fileOptions = []
      this.customerDetail = {}
      this.fileID = ''
      this.fileDetail = {}
      this.installmentList = []
      this.selectedInstallments = []
      this.selectedInstallmentObjects = []
      this.selectedOtherFees = []
      this.selectedInvoices = []
      this.totalPayable = 0
      this.foundFiles = []
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.name
    },
    getFiles(id) {
      this.$http.get(`customer/customer/${id}/find/files`)
        .then(response => {
          this.fileOptions = response.data.files
          this.fileID = ''
          this.fileDetail = {}
          this.installmentList = []
          this.selectedInstallments = []
          this.selectedInstallmentObjects = []
          this.selectedOtherFees = []
          this.selectedInvoices = []
          this.totalPayable = 0
          this.selectedInstallments = []
          this.selectedInstallmentObjects = []
          this.selectedInvoices = []
          this.selectedInvoiceObjects = []
          this.selectedOtherFees = []
          this.selectAllOtherFeesObjects = []
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setPaymentInformation() {
      this.$http.get(`customer/files/${this.fileID}/payment-detail`)
        .then(response => {
          if (this.$route.query.fileID) {
            const purchaser = response.data.file.purchasers[0]
            this.customerDetail = purchaser
            this.fileQuery = response.data.file.fileNo
            this.query = purchaser.name
          }
          this.selectedInstallments = []
          this.selectedInstallmentObjects = []
          this.selectedInvoices = []
          this.selectedInvoiceObjects = []
          this.selectedOtherFees = []
          this.selectAllOtherFeesObjects = []
          this.fileDetail = response.data.file || {}
          this.installmentInvoices = response.data.installmentInvoices
          this.otherFees = response.data.reactivationFees
          this.otherInvoices = response.data.invoices
          if (this.fileDetail.installmentSchedule && this.fileDetail.installmentSchedule.length > 0) {
            const filteredInstallments = this.fileDetail.installmentSchedule.filter(o => o.paymentStatus !== 'Paid')
            if (filteredInstallments.length > 5) {
              this.viewAll = true
              this.installmentList = filteredInstallments.slice(0, 5)
            } else {
              this.installmentList = filteredInstallments
            }
          }
          if (this.fileDetail.nvsFileType === 'Funeral Service Package') {
            this.bankInCode = 'DBS 7171'
            this.bankInId = 974
          } else {
            this.bankInCode = 'DBS'
            this.bankInId = 272
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeCardOption() {
      this.cardOption = ''
      this.cardType = ''
      if (this.paymentMode === 'Cheque') {
        this.paymentRef = ''
      } else if (this.paymentMode === 'Card') {
        this.cardOption = 'One Time'
        this.cardType = 'Visa Card'
        this.paymentRef = 'Visa Card'
      } else if (this.paymentMode === 'GIRO') {
        this.cardOption = 'Bank Transfer'
        this.paymentRef = 'Bank Transfer'
      } else {
        this.paymentRef = this.paymentMode
      }
    },
    changeCardType() {
      this.cardType = ''
      if (this.cardOption === 'Bank Transfer' || this.cardOption === 'PayNow') {
        this.paymentRef = this.cardOption
      } else {
        this.paymentRef = ''
      }
    },
    changeCardItems() {
      this.paymentRef = this.cardType
    },
    showFullSchedule() {
      this.viewAll = false
      this.viewLess = true
      const filteredInstallments = this.fileDetail.installmentSchedule.filter(o => o.paymentStatus !== 'Paid')
      this.installmentList = filteredInstallments
    },
    hideFullSchedule() {
      this.viewAll = true
      this.viewLess = false
      const filteredInstallments = this.fileDetail.installmentSchedule.filter(o => o.paymentStatus !== 'Paid')
      this.installmentList = filteredInstallments.slice(0, 5)
    },
    resolveStatusVariant(status) {
      if (status === 'Paid') return 'success'
      if (status === 'Overdue') return 'danger'
      if (status === 'Upcoming') return 'info'
      if (status === 'Due') return 'warning'
      return 'primary'
    },
    selectPaymentObjects() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        let amount = this.totalPayable
        let actualPayableAmount = 0
        this.selectedInstallments = []
        this.selectedInstallmentObjects = []
        this.selectedInvoices = []
        this.selectedInvoiceObjects = []
        this.selectedOtherFees = []
        this.selectAllOtherFeesObjects = []
        this.remarksArray = []
        this.remarksOtherFeesArray = []
        this.remarksInvoiceArray = []
        this.remarksFinalArray = []
        this.upcomingItemsError = false

        await this.$nextTick()

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.fileDetail.installmentSchedule.length; index++) {
          const element = this.fileDetail.installmentSchedule[index]
          if (element.remaining > 0) {
            if (amount > 0) {
              if (amount >= element.remaining) {
                element.allocated = element.remaining
                amount -= element.remaining
              } else {
                element.allocated = amount
                amount = 0
              }

              this.selectedInstallments.push(element.sequence)
              this.selectedInstallmentObjects.push(element)
            }
          }
          actualPayableAmount += element.remaining
          if (amount <= 0) {
            break
          }
        }

        this.selectedInstallmentObjects = this.selectedInstallmentObjects.sort((a, b) => a.sequence - b.sequence)

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.otherFees.length; index++) {
          const element = this.otherFees[index]
          if (element.balance > 0) {
            if (amount > 0) {
              if (amount >= element.balance) {
                element.allocated = element.balance
                amount -= element.balance
              } else {
                element.allocated = amount
                amount = 0
              }

              this.selectedOtherFees.push(element.trxID)
              this.selectAllOtherFeesObjects.push(element)
            }
          }
          actualPayableAmount += element.balance

          if (amount <= 0) {
            break
          }
        }

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.otherInvoices.length; index++) {
          const element = this.otherInvoices[index]
          if (element.balance > 0) {
            if (amount > 0) {
              if (amount >= element.balance) {
                element.allocated = element.balance
                amount -= element.balance
              } else {
                element.allocated = amount
                amount = 0
              }

              this.selectedInvoices.push(element.trxID)
              this.selectedInvoiceObjects.push(element)
            }
          }
          actualPayableAmount += element.balance
          if (amount <= 0) {
            break
          }
        }

        this.actualPayable = actualPayableAmount
        if (amount > 0) {
          this.showMoreAmountError = true
          this.moreAmount = amount
        } else {
          this.showMoreAmountError = false
        }
        // this.selectedInstallmentObjects.forEach(i => {
        //   // if (i.paymentStatus === 'Upcoming') {
        //   //   this.upcomingItemsError = true
        //   // }
        //   this.remarksArray.push(`Instmt#${i.sequence}_${moment(i.dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')}`)
        // })
        if (this.selectedInstallmentObjects.length === 0) {
          this.remarksArray = []
        } else if (this.selectedInstallmentObjects.length === 1) {
          const text = `INSTALLMENT #${this.selectedInstallmentObjects[0].sequence}, ${moment(this.selectedInstallmentObjects[0].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')}`
          this.remarksArray = [text]
        } else {
        // eslint-disable-next-line prefer-destructuring
          const length = this.selectedInstallmentObjects.length
          const text = `INSTALLMENT #${this.selectedInstallmentObjects[0].sequence}, ${moment(this.selectedInstallmentObjects[0].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')} - INSTALLMENT #${this.selectedInstallmentObjects[length - 1].sequence}, ${moment(this.selectedInstallmentObjects[length - 1].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')} (${length} MONTHS)`
          this.remarksArray = [text]
        }
        let reactivationFeesAmount = 0
        this.selectAllOtherFeesObjects.forEach(i => {
          // this.remarksOtherFeesArray.push(`Reactvn_Fees($${i.allocated})`)
          reactivationFeesAmount += Number(i.allocated)
        })
        if (reactivationFeesAmount > 0) {
          this.remarksOtherFeesArray = [`REACTIVATION FEES ($${reactivationFeesAmount})`]
        }
        this.selectedInvoiceObjects.forEach(i => {
          this.remarksInvoiceArray.push(`INV(${i.trxNo} $${i.allocated})`)
        })
        this.remarksFinalArray = this.remarksArray.concat(this.remarksOtherFeesArray, this.remarksInvoiceArray)
        this.remarks = this.remarksFinalArray.join(', ')
      }, this.debounceMilliseconds)
    },
    selectAllInstallments() {
      // this.totalPayable = 0
      this.remarksArray = []
      this.upcomingItemsError = false
      this.selectedInstallments = []
      let reducedAmount = 0
      this.selectedInstallmentObjects.forEach(installment => {
        reducedAmount += Number(installment.remaining)
      })
      // this.totalPayable -= reducedAmount
      this.selectedInstallmentObjects = []
      let addedAmount = 0
      this.fileDetail.installmentSchedule.forEach(element => {
        if (element.remaining > 0) {
          addedAmount += Number(element.remaining)
          this.selectedInstallments.push(element.sequence)
          // eslint-disable-next-line no-param-reassign
          element.allocated = element.remaining
          this.selectedInstallmentObjects.push(element)
        }
      })
      this.selectedInstallmentObjects = this.selectedInstallmentObjects.sort((a, b) => a.sequence - b.sequence)
      this.totalPayable += (addedAmount - reducedAmount)
      // this.selectedInstallmentObjects.forEach(i => {
      //   // if (i.paymentStatus === 'Upcoming') {
      //   //   this.upcomingItemsError = true
      //   // }
      //   this.remarksArray.push(`Instmt#${i.sequence}_${moment(i.dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')}`)
      // })
      if (this.selectedInstallmentObjects.length === 0) {
        this.remarksArray = []
      } else if (this.selectedInstallmentObjects.length === 1) {
        const text = `INSTALLMENT #${this.selectedInstallmentObjects[0].sequence}, ${moment(this.selectedInstallmentObjects[0].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')}`
        this.remarksArray = [text]
      } else {
        // eslint-disable-next-line prefer-destructuring
        const length = this.selectedInstallmentObjects.length
        const text = `INSTALLMENT #${this.selectedInstallmentObjects[0].sequence}, ${moment(this.selectedInstallmentObjects[0].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')} - INSTALLMENT #${this.selectedInstallmentObjects[length - 1].sequence}, ${moment(this.selectedInstallmentObjects[length - 1].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')} (${length} MONTHS)`
        this.remarksArray = [text]
      }
      this.remarksFinalArray = this.remarksArray.concat(this.remarksOtherFeesArray, this.remarksInvoiceArray)
      this.remarks = this.remarksFinalArray.join(', ')
    },
    selectInstallment(installment) {
      this.remarksArray = []
      this.upcomingItemsError = false
      const foundIndex = this.selectedInstallmentObjects.indexOf(installment)
      if (foundIndex === -1) {
        if (installment.remaining > 0) {
          // this.totalPayable = Number(this.totalPayable) + Number(installment.remaining)
          // eslint-disable-next-line no-param-reassign
          installment.allocated = installment.remaining
          this.selectedInstallmentObjects.push(installment)
          // console.log(installment.sequence)
          let addedAmount = 0
          this.fileDetail.installmentSchedule.forEach(element => {
            if (this.selectedInstallmentObjects.indexOf(element) === -1 && element.remaining > 0 && element.sequence < installment.sequence) {
              addedAmount += Number(element.remaining)
              this.selectedInstallments.push(element.sequence)
              // eslint-disable-next-line no-param-reassign
              element.allocated = element.remaining
              this.selectedInstallmentObjects.push(element)
            }
          })
          this.selectedInstallmentObjects = this.selectedInstallmentObjects.sort((a, b) => a.sequence - b.sequence)
          this.totalPayable += (addedAmount + Number(installment.remaining))
        } else {
          this.selectedInstallments.splice(this.selectedInstallments.indexOf(installment.sequence), 1)
        }
      } else {
        // console.log('foundIndex else')
        // this.totalPayable -= Number(installment.allocated)
        // this.selectedInstallments.splice(this.selectedInstallments.indexOf(installment.sequence), 1)
        this.selectedInstallmentObjects.splice(this.selectedInstallmentObjects.indexOf(installment), 1)
        // console.log(installment.sequence)
        const selectedInObjs = this.selectedInstallmentObjects.filter(seInstallment => seInstallment.sequence > installment.sequence)
        // console.log(selectedInObjs)
        let reducedAmount = 0
        selectedInObjs.forEach(element => {
          this.selectedInstallments.splice(this.selectedInstallments.indexOf(element.sequence), 1)
          this.selectedInstallmentObjects.splice(this.selectedInstallmentObjects.indexOf(element), 1)
          reducedAmount += Number(element.allocated)
        })
        this.selectedInstallmentObjects = this.selectedInstallmentObjects.sort((a, b) => a.sequence - b.sequence)
        this.totalPayable -= (reducedAmount + Number(installment.allocated))
      }
      // consequence check
      // const previousSeq = installment.sequence > 1 ? (installment.sequence - 1) : 1
      // const exist = this.installmentList.find(o => o.sequence === previousSeq)
      // const foundPrevIndex = this.selectedInstallments.indexOf(previousSeq)
      // console.log(this.selectedInstallments)
      // console.log(foundPrevIndex)
      // if (exist && foundPrevIndex === -1) {
      //   console.log('Yes')
      // } else {
      //   console.log('No')
      // }
      // this.selectedInstallmentObjects.forEach(i => {
      //   // if (i.paymentStatus === 'Upcoming') {
      //   //   this.upcomingItemsError = true
      //   // }
      //   this.remarksArray.push(`Instmt#${i.sequence}_${moment(i.dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')}`)
      // })
      if (this.selectedInstallmentObjects.length === 0) {
        this.remarksArray = []
      } else if (this.selectedInstallmentObjects.length === 1) {
        const text = `INSTALLMENT #${this.selectedInstallmentObjects[0].sequence}, ${moment(this.selectedInstallmentObjects[0].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')}`
        this.remarksArray = [text]
      } else {
        // eslint-disable-next-line prefer-destructuring
        const length = this.selectedInstallmentObjects.length
        const text = `INSTALLMENT #${this.selectedInstallmentObjects[0].sequence}, ${moment(this.selectedInstallmentObjects[0].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')} - INSTALLMENT #${this.selectedInstallmentObjects[length - 1].sequence}, ${moment(this.selectedInstallmentObjects[length - 1].dueDate).utcOffset(process.env.VUE_APP_TIME_ZONE).format('MM/YY')} (${length} MONTHS)`
        this.remarksArray = [text]
      }
      this.remarksFinalArray = this.remarksArray.concat(this.remarksOtherFeesArray, this.remarksInvoiceArray)
      this.remarks = this.remarksFinalArray.join(', ')
    },
    selectAllOtherFees() {
      this.remarksOtherFeesArray = []
      // this.selectedOtherFees = []
      // this.selectAllOtherFeesObjects = []
      // this.totalPayable = Number(this.totalPayable) + 0
      let addedAmount = 0
      this.otherFees.forEach(element => {
        if (element.balance > 0) {
          // this.totalPayable = Number(this.totalPayable) + Number(element.balance)
          // this.selectedOtherFees.push(element.trxID)
          // this.selectAllOtherFeesObjects.push(element)
          const foundIndex = this.selectAllOtherFeesObjects.indexOf(element)
          if (foundIndex === -1) {
            if (element.balance > 0) {
              addedAmount += Number(element.balance)
              this.selectedOtherFees.push(element.trxID)
              // eslint-disable-next-line no-param-reassign
              element.allocated = element.balance
              this.selectAllOtherFeesObjects.push(element)
            }
          }
        }
      })
      this.totalPayable += addedAmount
      let reactivationFeesAmount = 0
      this.selectAllOtherFeesObjects.forEach(i => {
        // this.remarksOtherFeesArray.push(`Reactvn_Fees($${i.allocated})`)
        reactivationFeesAmount += Number(i.allocated)
      })
      if (reactivationFeesAmount > 0) {
        this.remarksOtherFeesArray = [`REACTIVATION FEES ($${reactivationFeesAmount})`]
      }
      this.remarksFinalArray = this.remarksArray.concat(this.remarksOtherFeesArray, this.remarksInvoiceArray)
      this.remarks = this.remarksFinalArray.join(', ')
    },
    selectOtherFee(invoice) {
      this.remarksOtherFeesArray = []
      const foundIndex = this.selectAllOtherFeesObjects.indexOf(invoice)
      if (foundIndex === -1) {
        if (invoice.balance > 0) {
          this.totalPayable += Number(invoice.balance)
          // eslint-disable-next-line no-param-reassign
          invoice.allocated = invoice.balance
          this.selectAllOtherFeesObjects.push(invoice)
        } else {
          this.selectedOtherFees.splice(this.selectedOtherFees.indexOf(invoice.trxID), 1)
        }
      } else {
        this.totalPayable -= Number(invoice.balance)
        this.selectAllOtherFeesObjects.splice(foundIndex, 1)
      }
      let reactivationFeesAmount = 0
      this.selectAllOtherFeesObjects.forEach(i => {
        // this.remarksOtherFeesArray.push(`Reactvn_Fees($${i.allocated})`)
        reactivationFeesAmount += Number(i.allocated)
      })
      if (reactivationFeesAmount > 0) {
        this.remarksOtherFeesArray = [`REACTIVATION FEES ($${reactivationFeesAmount})`]
      }
      this.remarksFinalArray = this.remarksArray.concat(this.remarksOtherFeesArray, this.remarksInvoiceArray)
      this.remarks = this.remarksFinalArray.join(', ')
    },
    selectAllInvoices() {
      // this.selectedInvoices = []
      // this.selectedInvoiceObjects = []
      let addedAmount = 0
      this.otherInvoices.forEach(element => {
        // if (element.balance > 0) {
        //   this.totalPayable = Number(this.totalPayable) + Number(element.balance)
        //   this.selectedInvoices.push(element.trxID)
        //   this.selectedInvoiceObjects.push(element)
        // }
        const foundIndex = this.selectedInvoiceObjects.indexOf(element)
        if (foundIndex === -1) {
          if (element.balance > 0) {
            addedAmount += Number(element.balance)
            this.selectedInvoices.push(element.trxID)
            // eslint-disable-next-line no-param-reassign
            element.allocated = element.balance
            this.selectedInvoiceObjects.push(element)
          }
        }
      })
      this.totalPayable += addedAmount
      this.selectedInvoiceObjects.forEach(i => {
        this.remarksInvoiceArray.push(`INV(${i.trxNo} $${i.allocated})`)
      })
      this.remarksFinalArray = this.remarksArray.concat(this.remarksOtherFeesArray, this.remarksInvoiceArray)
      this.remarks = this.remarksFinalArray.join(', ')

      // this.totalPayable = this.numberFormat(this.totalPayable)
    },
    selectInvoice(invoice) {
      const foundIndex = this.selectedInvoiceObjects.indexOf(invoice)
      if (foundIndex === -1) {
        if (invoice.balance > 0) {
          this.totalPayable += Number(invoice.balance)
          // eslint-disable-next-line no-param-reassign
          invoice.allocated = invoice.balance
          this.selectedInvoiceObjects.push(invoice)
        } else {
          this.selectedInvoices.splice(this.selectedInvoices.indexOf(invoice.trxID), 1)
        }
      } else {
        this.totalPayable -= Number(invoice.balance)
        this.selectedInvoiceObjects.splice(foundIndex, 1)
      }
      this.selectedInvoiceObjects.forEach(i => {
        this.remarksInvoiceArray.push(`INV(${i.trxNo} $${i.allocated})`)
      })
      this.remarksFinalArray = this.remarksArray.concat(this.remarksOtherFeesArray, this.remarksInvoiceArray)
      this.remarks = this.remarksFinalArray.join(', ')
    },
    closePaymentPreviewModal() {
      this.$root.$emit('bv::hide::modal', 'modal-payment-items', '')
    },
    getVariant(key) {
      if (key % 4 === 0) return 'info'
      if (key % 4 === 1) return 'warning'
      if (key % 4 === 2) return 'danger'
      if (key % 4 === 3) return 'purple'
      return 'primary'
    },
    previewForm() {
      this.consequent_error = false
      this.$refs.paymentCreateForm.validate().then(success => {
        // console.log(this.selectedInstallmentObjects.length)
        this.installmentList.forEach((install, index) => {
          // console.log(install.sequence)
          // console.log(index)
          if (index < this.selectedInstallmentObjects.length) {
            if (!this.selectedInstallments.includes(install.sequence)) {
              this.consequent_error = true
            }
          }
        })
        // const exist = this.installmentList.find(o => o.sequence === previousSeq)
        if (success) {
          this.$root.$emit('bv::show::modal', 'modal-payment-items', '')
        }
      })
    },
    submitForm() {
      const formData = new FormData()

      let finalPaymentType = this.paymentMode
      if (finalPaymentType === 'Card') {
        if (this.cardOption === 'Direct Debit') {
          finalPaymentType = 'Direct Debit'
        } else {
          finalPaymentType = this.cardType
        }
      }

      const paidInstallments = this.selectedInstallmentObjects
      const paidInvoices = []
      const reactivationFees = []
      this.selectedInvoiceObjects.forEach(i => {
        const obj = {
          trxID: i.trxID,
          trxNo: i.trxNo,
          allocated: i.allocated,
        }

        paidInvoices.push(obj)
      })
      this.selectAllOtherFeesObjects.forEach(i => {
        const obj = {
          trxID: i.trxID,
          trxNo: i.trxNo,
          allocated: i.allocated,
        }

        reactivationFees.push(obj)
      })

      const timezone = process.env.VUE_APP_TIME_ZONE

      formData.append('fileID', this.fileDetail.fileID)
      formData.append('nvsFileID', this.fileDetail._id)
      formData.append('paymentType', finalPaymentType)
      formData.append('remarks', this.remarks)
      formData.append('bankIn', this.bankInId)
      formData.append('trxDate', moment(this.transactionDate, 'DD/MM/YYYY').utcOffset(timezone).format('YYYY-MM-DD HH:mm'))
      formData.append('totalAmount', this.totalPayable)
      formData.append('paidInstallments', JSON.stringify(paidInstallments))
      formData.append('paidInvoices', JSON.stringify(paidInvoices))
      formData.append('reactivationFees', JSON.stringify(reactivationFees))

      this.$http.post('customer/receipts/register/payment', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.success) {
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'Payment Registered',
                text: `Payment for <strong>${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType || '-'} ${this.fileDetail.lotLocation ? `(${this.fileDetail.lotLocation})` : '-'}</strong> under <strong>${this.customerDetail.name} (${this.customerDetail.nric})</strong> is successfully registered.`,
                icon: 'CheckCircleIcon',
                variant: 'info',
                hideClose: true,
              },
            })
            const receiptFormData = new FormData()
            receiptFormData.append('url', window.location.origin)
            receiptFormData.append('receiptID', response.data.receipt.trxID)
            receiptFormData.append('id', response.data.receipt._id)
            this.$http.post('customer/receipts/pdf/download', receiptFormData, {
              headers: { Accept: 'application/pdf' },
              responseType: 'arraybuffer',
            })
              .then(res => {
                const blob = new Blob([res.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `Receipt-${response.data.receipt.trxNo}.pdf`
                link.click()

                link.remove()
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
            this.$router.push({ name: 'customers-files-show', params: { id: this.fileDetail.fileID }, query: { tab: 'payment', ledger: 'receipt' } })
          } else if (response.data.willRetry) {
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'Payment Register Failed Due to SCLC Connection Error, Will Retry To Process Later',
                text: `Payment for <strong>${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType || '-'} ${this.fileDetail.lotLocation ? `(${this.fileDetail.lotLocation})` : '-'}</strong> under <strong>${this.customerDetail.name} (${this.customerDetail.nric})</strong> is failed due to <strong>${response.data.message}</strong>`,
                icon: 'AlertCircleIcon',
                variant: 'danger',
                hideClose: true,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'Payment Register Failed',
                text: `Payment for <strong>${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType || '-'} ${this.fileDetail.lotLocation ? `(${this.fileDetail.lotLocation})` : '-'}</strong> under <strong>${this.customerDetail.name} (${this.customerDetail.nric})</strong> is failed due to <strong>${response.data.message}</strong>`,
                icon: 'AlertCircleIcon',
                variant: 'danger',
                hideClose: true,
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'status') {
                this.statusError = validationError.msg
                this.statusValidation = true
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    // submitForm() {
    //   const formData = new FormData()

    //   let finalPaymentType = this.paymentMode
    //   if (finalPaymentType === 'Card') {
    //     if (this.cardOption === 'Direct Debit') {
    //       finalPaymentType = 'Direct Debit'
    //     } else {
    //       finalPaymentType = this.cardType
    //     }
    //   }

    //   const allocations = []
    //   // let installmentID = ''
    //   let installmentAllocation = 0
    //   this.selectedInstallmentObjects.forEach(i => {
    //     // installmentID = i.trxID
    //     installmentAllocation += i.allocated
    //   })

    //   if (installmentAllocation > 0) {
    //     let installmentAllocatedAmount = installmentAllocation
    //     const sortedInstallmentInvoices = this.installmentInvoices.sort((a, b) => a.trxDate - b.trxDate)
    //     const sortedInstallments = []
    //     sortedInstallmentInvoices.forEach(i => {
    //       i.billings.forEach(b => {
    //         if (b.remaining > 0) {
    //           sortedInstallments.push(b)
    //         }
    //       })
    //     })
    //     // eslint-disable-next-line no-restricted-syntax
    //     for (const i of sortedInstallments) {
    //       if (installmentAllocatedAmount > 0) {
    //         if (installmentAllocatedAmount >= i.remaining) {
    //           allocations.push({
    //             TrxDetail_Id: i.trxDetailID,
    //             Allocate_Amount: i.remaining,
    //           })
    //           installmentAllocatedAmount -= i.remaining
    //         } else {
    //           allocations.push({
    //             TrxDetail_Id: i.trxDetailID,
    //             Allocate_Amount: installmentAllocatedAmount,
    //           })
    //           installmentAllocatedAmount = 0
    //         }
    //       } else {
    //         break
    //       }
    //     }
    //   }
    //   this.selectedInvoiceObjects.forEach(i => {
    //     let allocatedAmount = i.allocated
    //     if (allocatedAmount > 0) {
    //       // eslint-disable-next-line no-restricted-syntax
    //       for (const b of i.billings) {
    //         if (b.remaining > 0) {
    //           if (allocatedAmount >= b.remaining) {
    //             allocations.push({
    //               TrxDetail_Id: b.trxDetailID,
    //               Allocate_Amount: b.remaining,
    //             })
    //             allocatedAmount -= b.remaining
    //           } else {
    //             allocations.push({
    //               TrxDetail_Id: b.trxDetailID,
    //               Allocate_Amount: allocatedAmount,
    //             })
    //             allocatedAmount = 0
    //           }
    //         }

    //         if (allocatedAmount === 0) {
    //           break
    //         }
    //       }
    //     }
    //   })
    //   this.selectAllOtherFeesObjects.forEach(i => {
    //     let allocatedAmount = i.allocated
    //     if (allocatedAmount > 0) {
    //       // eslint-disable-next-line no-restricted-syntax
    //       for (const b of i.billings) {
    //         if (b.remaining > 0) {
    //           if (allocatedAmount >= b.remaining) {
    //             allocations.push({
    //               TrxDetail_Id: b.trxDetailID,
    //               Allocate_Amount: b.remaining,
    //             })
    //             allocatedAmount -= b.remaining
    //           } else {
    //             allocations.push({
    //               TrxDetail_Id: b.trxDetailID,
    //               Allocate_Amount: allocatedAmount,
    //             })
    //             allocatedAmount = 0
    //           }
    //         }

    //         if (allocatedAmount === 0) {
    //           break
    //         }
    //       }
    //     }
    //   })

    //   const timezone = process.env.VUE_APP_TIME_ZONE

    //   formData.append('fileID', this.fileDetail.fileID)
    //   formData.append('nvsFileID', this.fileDetail._id)
    //   formData.append('paymentType', finalPaymentType)
    //   formData.append('remarks', this.remarks)
    //   formData.append('bankIn', this.bankInId)
    //   formData.append('trxDate', moment(this.transactionDate, 'DD/MM/YYYY').utcOffset(timezone).format('YYYY-MM-DD HH:mm'))
    //   formData.append('totalAmount', this.totalPayable)
    //   formData.append('allocations', JSON.stringify(allocations))

    //   this.$http.post('customer/receipts/register/payment', formData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
    //     .then(response => {
    //       if (response.data.success) {
    //         this.$toast({
    //           component: ToastificationContentCustom,
    //           props: {
    //             title: 'Payment Registered',
    //             text: `Payment for <strong>${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType || '-'} ${this.fileDetail.lotLocation ? `(${this.fileDetail.lotLocation})` : '-'}</strong> under <strong>${this.customerDetail.name} (${this.customerDetail.nric})</strong> is successfully registered.`,
    //             icon: 'CheckCircleIcon',
    //             variant: 'success',
    //             hideClose: true,
    //           },
    //         })
    //         this.$router.push({ name: 'customers-files-show', params: { id: this.fileDetail.fileID }, query: { tab: 'payment', ledger: 'receipt' } })
    //       } else {
    //         this.$toast({
    //           component: ToastificationContentCustom,
    //           props: {
    //             title: 'Payment Register Failed',
    //             text: `Payment for <strong>${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType || '-'} ${this.fileDetail.lotLocation ? `(${this.fileDetail.lotLocation})` : '-'}</strong> under <strong>${this.customerDetail.name} (${this.customerDetail.nric})</strong> is failed due to <strong>${response.data.message}</strong>`,
    //             icon: 'AlertCircleIcon',
    //             variant: 'danger',
    //             hideClose: true,
    //           },
    //         })
    //       }
    //     })
    //     .catch(error => {
    //       if (error.data.errors) {
    //         error.data.errors.forEach(validationError => {
    //           if (validationError.param === 'status') {
    //             this.statusError = validationError.msg
    //             this.statusValidation = true
    //           }
    //         })
    //       } else {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //             icon: 'AlertTriangleIcon',
    //             variant: 'danger',
    //           },
    //         })
    //       }
    //     })
    // },
  },
}
</script>

<style lang="scss">
.timeline-variant-info .timeline-item-point {
  background-color: #00cfe8 !important;
}
.timeline-variant-purple .timeline-item-point {
  background-color: #7367F0 !important;
}
.register__payment-left-media{
    gap: 10px;
    .register__payment-left-media-text{
      p{
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.6px;
        text-align: left;
      }
      h4{
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 21.94px;
        text-align: left;
        color: #333333;
      }
    }
}

.register__payment-right-media-wrapper{
   display: flex;
   justify-content: end;
   gap: 30px;
}

.register__payment-right-media{
    .register__payment-right-media-text{
      margin-top: 5px;
      p{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #999999;
      }
      h4{
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 21.94px;
        text-align: left;
        color: #333333;
      }
    }
}
.installment__table{
     td{
        border-top: none;
        border-bottom: 1px solid #D2DCEA;
        &:first-child{
            padding-left: 0 !important;
        }
     }
     .installment__checkbox{
       min-height: unset !important;
     }
     .installment__description{
       padding-left: 6px;
     }
}
.payment__card.card{
    padding: 20px !important;
    background-color: #F5F8FB;
}
.payment__card-large-input-prepend{
    .input-group-text{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #CBCBCB;
        border-left: 1px solid #D2DCEA !important;
        &:focus{
          border-left: 1px solid #104D9D !important;
        }
    }
}
.card-body .payment__card-large-input-prepend .form-control{
   border-right: 1px solid #D2DCEA !important;
   &:focus{
    border-right: 1px solid #104D9D !important;
    }
}
.payment__card-large-input{
    height: 58px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #333333 !important;
}
.register__payment-round-block{
    display: flex;
    justify-content: center;
    padding-right: 10px;
}
.register__payment-round-number {
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border-radius: 20px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #333333;
  }
.payment__for-alert{
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 6px;
    margin-top: 20px;
    .payment__for-alert-icon{
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
}

.register__payment-modal-alert{
    background-color: #D91B3526;
    padding: 10px;
    border-radius: 6px;
    .register__payment-modal-icon{
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
}

.stock-items-modal-header{
    margin: 20px auto 0px auto;
    text-align: center;
  }
.register__payment-button-td{
   border-bottom: none !important;
}

.payment__for-card-primary{
  background-color: #D2DCEA;
  padding: 12px 20px;
  border-radius: 6px;
    .payment__for-table{
      margin-bottom: 0;
      td{
        padding: 2.5px !important;
      }
    }
}

.payment__for-card-danger{
  background-color: #D91B3526;
  padding: 12px 20px;
  border-radius: 6px;
    .payment__for-table{
      margin-bottom: 0;
      td{
        padding: 2.5px !important;
      }
    }
}

.register__payment-icon-checkbox{
      background-color: #FFFFFF;
      border-color: #D2DCEA !important;
      &.active{
         background-color: #104D9D !important;
         border-color: #104D9D !important;
         .register__payment-icon {
            filter:  brightness(0) invert(1);
        }
        .register__payment-text{
           color: #FFFFFF !important;
        }
      }
}
.register__payment-logo-checkbox{
      background-color: #FFFFFF;
      border-color: #D2DCEA !important;
      .register__payment-logo-white{
         margin-top: 7px;
      }
      &.active{
         background-color: #104D9D !important;
         border-color: #104D9D !important;
         .register__payment-logo-white {
            filter:  brightness(0) invert(1);
        }
      }
}
.register__payment-text{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #333333;
}
.register__payment-label{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  letter-spacing: 0.4px;
  color: #000000;
  text-align: left;
}

.z-index-99{
  z-index: 99;
}
.result-search-header{
 font-family: Montserrat;
 font-size: 14px;
 font-weight: 600;
 line-height: 18px;
 text-align: left;
 color: #333333;
}
.card-body .input-group .input__append-border-right{
 border-right: 1px solid #D2DCEA !important;
}
.card-body .input-group .input__append-border-right:focus{
    border-color: #104D9D !important;
}
</style>
